import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if notification.isSupported}}\n  {{#if notification.isEnabled}}\n    {{#if notification.isSoundEnabled}}\n      <button {{action 'disable'}} class='btn btn-default' type='button'>\n        <FaIcon @icon='volume-high' />\n      </button>\n    {{else}}\n      <button {{action 'soundOn'}} class='btn btn-default' type='button'>\n        <FaIcon @icon='bell' />\n      </button>\n    {{/if}}\n  {{else}}\n    <button {{action 'activate'}} class='btn btn-default' type='button'>\n      <FaIcon @icon='bell-slash' />\n    </button>\n  {{/if}}\n{{/if}}", {"contents":"{{#if notification.isSupported}}\n  {{#if notification.isEnabled}}\n    {{#if notification.isSoundEnabled}}\n      <button {{action 'disable'}} class='btn btn-default' type='button'>\n        <FaIcon @icon='volume-high' />\n      </button>\n    {{else}}\n      <button {{action 'soundOn'}} class='btn btn-default' type='button'>\n        <FaIcon @icon='bell' />\n      </button>\n    {{/if}}\n  {{else}}\n    <button {{action 'activate'}} class='btn btn-default' type='button'>\n      <FaIcon @icon='bell-slash' />\n    </button>\n  {{/if}}\n{{/if}}","moduleName":"amber-ui/components/quick-post-notification-button.hbs","parseOptions":{"srcName":"amber-ui/components/quick-post-notification-button.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  session: service(),
  notification: service(),
  actions: {
    sendTest() {
      this.notification.new(
        'Test',
        'Dit is een test bericht',
        '/images/alphalogonotext.png'
      );
    },
    activate() {
      this.notification.getPermission();
    },
    disable() {
      this.notification.turnOff();
      this.send('sendTest');
    },
    soundOn() {
      this.notification.toggleSound();
      this.send('sendTest');
    },
  },
});
