import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"public-index\">\n  <Public::Index::Header/>\n\n  <Public::Index::AboutAlpha />\n\n  <Public::Index::YoutubeVideos />\n\n  <div class=\"row\">\n    <Public::Index::Activities @doubleActivityColumns={{doubleActivityColumns}} @activities={{activities}} @class=\"col-12 {{if doubleActivityColumns 'col-lg-8' 'col-lg-5'}}\" />\n    <Public::Index::PhotoAlbums @doubleActivityColumns={{doubleActivityColumns}} @class=\"col-12 {{if doubleActivityColumns 'col-lg-4' 'col-lg-7'}}\" />\n  </div>\n\n  <Public::Index::SponsorBanner />\n</div>\n", {"contents":"<div class=\"public-index\">\n  <Public::Index::Header/>\n\n  <Public::Index::AboutAlpha />\n\n  <Public::Index::YoutubeVideos />\n\n  <div class=\"row\">\n    <Public::Index::Activities @doubleActivityColumns={{doubleActivityColumns}} @activities={{activities}} @class=\"col-12 {{if doubleActivityColumns 'col-lg-8' 'col-lg-5'}}\" />\n    <Public::Index::PhotoAlbums @doubleActivityColumns={{doubleActivityColumns}} @class=\"col-12 {{if doubleActivityColumns 'col-lg-4' 'col-lg-7'}}\" />\n  </div>\n\n  <Public::Index::SponsorBanner />\n</div>\n","moduleName":"amber-ui/components/index/public.hbs","parseOptions":{"srcName":"amber-ui/components/index/public.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';

export default class PublicComponent extends Component {
  @service store;
  @service session;

  @tracked doubleActivityColumns;
  @tracked activities;

  constructor() {
    super(...arguments);
    const params = {
      filter: { upcoming: true },
      sort: 'start_time',
      page: { size: 6 },
    };

    this.store.query('activity', params).then((retrievedActivities) => {
      this.activities = retrievedActivities;
      this.doubleActivityColumns = retrievedActivities.length > 3;
    });
  }
}
